import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Image from "../../../../components/Image";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearState } from "../../../../redux/loginSlice";
import { logoutUser } from "../../../../redux/logoutSlice";
import { Link as ScrollLink } from "react-scroll";
import CustomDropdown from "../../CustomDropdown";
import { notify } from "../../../../components/Notification";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import doAPI from "../../../../api";

const RegisterOptions = [
  {
    label: "Player",
    link: "/player-register",
  },
  {
    label: "Coach",
    link: "/coach-register",
  },
  {
    label: "Club Member",
    link: "/club-member-register",
  },
];

const LoginOptions = [
  {
    label: "Player Login",
    link: "/player-login",
  },
  {
    label: "Coach Login",
    link: "/coach-login",
  },
  {
    label: "Academy Login",
    link: "/academy-login",
  },
  {
    label: "Club Member Login",
    link: "/club-member-login",
  },
];

const Header2 = ({ blackFont }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [scrolled, setScrolled] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const [profile, setProfile] = useState();
  const loginState = useSelector(state => state.login);
  const frontendState = useSelector(state => state.frontend.customizationData);
  const [openDropdown, setOpenDropdown] = useState(null);

  const handleDropdownClick = dropdown => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  const logout = () => {
    dispatch(logoutUser());

    window.localStorage.clear();
    notify("Logout successfully", "error");
    if (loginState.user_type === "player") {
      navigate("/player-login");
    } else if (loginState.user_type === "coach") {
      navigate("/coach-login");
    } else if (loginState.user_type === "academy") {
      navigate("/academy-login");
    }
    dispatch(clearState());
  };

  const userTypes = ["player", "coach", "academy"];

  useEffect(() => {
    if (userTypes.includes(loginState.user_type)) {
      doAPI
        .getData(`${loginState.user_type}/details`)
        .then(res => {
          const response = res.data;
          if (response.status) {
            setProfile(response.data);
          }
        })
        .catch(error => {
          if (error.response.statusText === "Unauthorized") {
            localStorage.clear();
            logout();
          }
        });
    }
  }, []);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <nav
        className={`absolute top-0 z-50 w-full bg-white p-3 lg:bg-transparent lg:pb-[40px] lg:pt-[30px] lg:text-white ${blackFont ? "lg:text-black" : "lg:text-white"} `}>
        <div className="container-px mx-auto hidden lg:grid lg:grid-cols-12 lg:items-center lg:justify-items-center">
          <div className="col-span-2">
            <Link to="/">
              <Image src={frontendState?.logo} height={50} />
            </Link>
          </div>
          <div className={`${loginState.access_token !== "" ? "col-span-10" : "col-span-8"} flex justify-center space-x-5 justify-self-end`}>
            <Link className="nav-items" to="/home">
              Home
            </Link>

            {loginState.access_token !== "" && (
              <>
                <Link className="nav-items" to="/dashboard">
                  Dashboard
                </Link>
                {/* <Link className="nav-items" to="/tournaments/tournaments-view">
                  Tournament
                </Link> */}
              </>
            )}

            {/* {isDlta && ( */}
            {/* <Link className="nav-items" to="/tournament-calendar">
              Tournaments
            </Link> */}
            {/* )} */}
            {/* ) : (
              <ScrollLink className="nav-items" to="tournaments" smooth={true} offset={-50}>
                Tournament
              </ScrollLink>
            )} */}

            {/* <ScrollLink className="nav-items" to="players" smooth={true} offset={-50}>
              Players
            </ScrollLink> */}

            {/* <ScrollLink className="nav-items" to="about" smooth={true} offset={-50}>
              About Us
            </ScrollLink> */}

            {loginState.access_token !== "" ? (
              <CustomDropdown
                dropDownIcon
                title={<>My Account</>}
                className="nav-items"
                options={[
                  {
                    label: frontendState ? frontendState.first_name + " " + frontendState.family_name : "Player",
                    link: "/my-profile",
                  },
                  {
                    label: "Logout",
                    onClick: logout,
                  },
                ]}
              />
            ) : (
              <CustomDropdown dropDownIcon title={<>Login</>} className="nav-items" options={LoginOptions} />
            )}
          </div>
          {loginState.access_token === "" && (
            <div className="col-span-2">
              <CustomDropdown dropDownIcon title={<>Join Us</>} className="nav-items-active" options={RegisterOptions} />
            </div>
          )}
        </div>
        <div className="container-px flex items-center lg:hidden">
          <div className="w-100 grow-1 text-xl font-bold">
            <Image src={frontendState?.logo} height={35} />
          </div>
          <div>
            <button
              onClick={() => {
                setIsOpen(!isOpen);
                setScrolled(true);
              }}
              className="border-none bg-inherit p-2 text-black">
              <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}></path>
              </svg>
            </button>
          </div>
        </div>
        {isOpen && (
          <div className="lg:hidden">
            <div className="mt-4 flex flex-col space-y-4">
              <Link className="nav-items" to="/">
                Home
              </Link>

              {loginState.access_token !== "" && (
                <Link className="nav-items" to="/dashboard">
                  Dashboard
                </Link>
              )}
              {/*
                <Link className="nav-items" to="/tournaments">
                  Tournaments
                </Link>
              */}

              {/* <ScrollLink className="nav-items" to="players" smooth={true} offset={-70}>
                Players
              </ScrollLink>

              <ScrollLink className="nav-items" to="about" smooth={true} offset={-70}>
                About Us
              </ScrollLink> */}

              {loginState.access_token !== "" ? (
                <>
                  <div className="nav-items flex items-center gap-2" onClick={() => handleDropdownClick("user")}>
                    My Account
                    <MdOutlineKeyboardArrowDown />
                  </div>
                  {openDropdown === "user" &&
                    [
                      {
                        label: frontendState ? frontendState.first_name + " " + frontendState.family_name : "Player",
                        link: "/my-profile",
                      },
                      {
                        label: "Logout",
                        onClick: logout,
                      },
                    ].map((item, key) =>
                      item.link ? (
                        <Link to={item.link} key={key} className="nav-items ml-4 capitalize">
                          {item?.label}
                        </Link>
                      ) : (
                        <div onClick={item.onClick} key={key} className="nav-items ml-4 capitalize">
                          {item?.label}
                        </div>
                      )
                    )}
                </>
              ) : (
                <>
                  <div className="nav-items flex items-center gap-2" onClick={() => handleDropdownClick("login")}>
                    Login
                    <MdOutlineKeyboardArrowDown />
                  </div>
                  {openDropdown === "login" &&
                    LoginOptions.map((item, key) => (
                      <Link to={item.link} key={key} className="nav-items ml-4 capitalize">
                        {item?.label}
                      </Link>
                    ))}
                  <div className="nav-items flex items-center gap-2" onClick={() => handleDropdownClick("register")}>
                    Register
                    <MdOutlineKeyboardArrowDown />
                  </div>
                  {openDropdown === "register" &&
                    RegisterOptions.map((item, key) => (
                      <Link to={item.link} key={key} className="nav-items ml-4 capitalize">
                        {item?.label}
                      </Link>
                    ))}
                </>
              )}
            </div>
          </div>
        )}
      </nav>
    </>
  );
};

export default Header2;
